import * as React from 'react'
import {graphql, Link, useStaticQuery} from "gatsby";
import Button from "./button";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";


export default function Hero(){
    const data = useStaticQuery(graphql`
{
  fileName: file(relativePath: {eq: "images/hero-home.jpg"}) {
    childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            width: 1080
            layout: FULL_WIDTH
          )
        }
  }
  allDataJson {
    edges {
        node {
            About_Us
            Better_Explanation
            Business_Name
            Contact_Details {
                Address
                Email
                Hours
                Phone
                Phone_Without_Formatting
                Service_Areas
            }
            Expert_Explanation
            Main_Services {
                Description
                Title
            }
            Other_Services {
                Description
                Title
            }
            Pages {
                Image
                Title
                Page_Address
            }
            Qualities
            Tag_Line
            Title
            Years_Experience
        }
    }
  }
}



`)
    const WebsiteContent = data.allDataJson.edges[0].node

    const ImageContent = data.fileName.childImageSharp.gatsbyImageData;

    return(
        <div>
            <div className={"w-full h-screen absolute overflow-hidden top-0 -z-10 brightness-75 dark:brightness-50"}>
                <StaticImage className={"h-full"} alt={"hero image"} objectPosition={"100% 0%"}  objectFit={"cover"} src={"https://ik.imagekit.io/7edcjy4g6/IMG_1844-min_odzTSO3MH.PNG?ik-sdk-version=javascript-1.4.3&updatedAt=1659745347646"} layout={"fullWidth"} />
            </div>
            <div className={"h-full lg:py-36 lg:pb-20 pb-20 pt-12 lg:pt-12 flex flex-col justify-center items-center max-w-3xl mx-auto font-bold text-white"}>

                <div className={"pb-10 pt-12"}>
                <div className={"flex flex-row items-center p-2 pb-5"}>

                    <svg className={"h-10 w-10 shrink-0 fill-main-color-600"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"/></svg>

                    <p className={"text-5xl pl-4 shrink-1 my-auto font-medium"}>Artificial Grass</p>
                </div>
                <div className={"flex flex-row items-center p-2  pb-5"}>

                    <svg className={"h-10 w-10 shrink-0 fill-main-color-600"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"/></svg>

                    <p className={"text-5xl pl-4 shrink-1 my-auto font-medium"}>Pavers and Concrete</p>
                </div>
                <div className={"flex flex-row items-center p-2  pb-5"}>

                    <svg className={"h-10 w-10 shrink-0 fill-main-color-600"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"/></svg>

                    <p className={"text-5xl pl-4 shrink-1 my-auto font-medium"}>Back Yard Remodeling</p>
                </div>
                <div className={"flex flex-row items-center p-2  pb-5"}>

                    <svg className={"h-10 w-10 shrink-0 fill-main-color-600"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"/></svg>

                    <p className={"text-5xl pl-4 shrink-1 my-auto font-medium"}>Swimming Pools and Spa</p>
                </div>
                </div>
                <div className={"h-1 w-24 bg-main-color-500"}></div>
                <p className={"text-2xl text-center text-white p-3 m-3   leading-loose bg-gray-500/75 rounded-xl"}>{WebsiteContent.Tag_Line}</p>
                <Button className={""} to={"/contact#contact-form"}>Get a Free Quote!</Button>
                <div className={"pt-5"}>
                <Button className={""} to={"tel:" + WebsiteContent.Contact_Details.Phone_Without_Formatting}>Call Now!</Button>
                </div>
            </div>
        </div>
    )
}