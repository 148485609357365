import * as React from 'react'
import StockBackground from "../images/general/forest.webp"
import {graphql, Link, useStaticQuery} from "gatsby";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";

function SingleImage(props){
    return(
            <img className={"sm:w-1/6 w-1/2"} src={props.src} alt={props.alt}/>
    )
}

function ImageGalleryShowcase(){
    const data = useStaticQuery(graphql`
{
allFile(filter: {relativeDirectory: {eq: "images/gallery"}}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
}



`)

    const ImageContent = data.allFile.edges

    return(
        <>
            <div className={"px-4 py-16 bg-main-color-500"}>
                <div className={"flex justify-evenly items-center flex-wrap max-w-5xl mx-auto"}>
                    <h1 className={"text-center text-6xl text-white font-bold sm:text-6xl max-w-lg"} >Our Work</h1>
                </div>
            </div>
        <div className={"flex flex-wrap dark:bg-gray-700 justify-center"}>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#artificial-grass"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/sdgfdsdfs_4u46QVh94.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814126013"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#artificial-grass"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/o__7__ce3T7Pf7z.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814125511"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#pavers-and-concrete"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/o__6__dwfTKYHY-.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814125259"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#pavers-and-concrete"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/o_5utsbtMmb.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814126051"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#artificial-grass"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/o__8__CBaKnGT2g6.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814125875"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#back-yard-remodelling"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/o__18__4qQDIvDn_.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814124310"}/></Link>

            <Link className={"sm:w-1/6 w-1/2"} to={"/services#back-yard-remodelling"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/o__4__4EuKh_4juZ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814124777"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#pavers-and-concrete"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/o__2__fdidj8s6wW.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814124528"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#back-yard-remodelling"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/o__19__f7TxIOkHd2.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814124585"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#back-yard-remodelling"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/o__5__kra08ZKCr.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814124951"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#back-yard-remodelling"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/o__15__AYyheeEL62.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814123776"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#artificial-grass"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/o__11__QYCtYLz4g.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814123390"}/></Link>

            <Link className={"sm:w-1/6 w-1/2"} to={"/services#artificial-grass"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/o__10__f2sgL6lhY.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814123252"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#artificial-grass"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/o__13__0XCZWbvMj1.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814123736"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#back-yard-remodelling"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/o__17__pDrH_fEgF.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814124102"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#artificial-grass"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/o__16__9gHuqVArm.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814124041"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#pavers-and-concrete"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/o__12__lO3GWiuszW.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814123644"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#back-yard-remodelling"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/jih_VR4VELuRB3.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814122877"}/></Link>

            <Link className={"sm:w-1/6 w-1/2"} to={"/services#swimming-pools-and-spa"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/IMG_1859_VFRYETEjG.PNG?ik-sdk-version=javascript-1.4.3&updatedAt=1659814122995"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#back-yard-remodelling"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/IMG_1861_hlHgcFsvfC.PNG?ik-sdk-version=javascript-1.4.3&updatedAt=1659814123110"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#pavers-and-concrete"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/IMG_1858_yj_wAJQ7q.PNG?ik-sdk-version=javascript-1.4.3&updatedAt=1659814122916"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#artificial-grass"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/fdssd_uN086PhdH.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814122650"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#artificial-grass"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/o__1__5W3jg0AaI.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659814122990"}/></Link>
            <Link className={"sm:w-1/6 w-1/2"} to={"/services#back-yard-remodelling"}><StaticImage className={"w-full h-full"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/gallery/IMG_1860_aOaBj5Bo7M.PNG?ik-sdk-version=javascript-1.4.3&updatedAt=1659814123018"}/></Link>
           
        </div>
        </>
    )
}

export default ImageGalleryShowcase