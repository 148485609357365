import * as React from 'react'
import StockBackground from "../data/images/hero-about.jpg";
import BackgroundImageSelf from "./background-image-self";

import {graphql, useStaticQuery} from "gatsby";

export default function ExperienceBar() {
    const data = useStaticQuery(graphql`
  query {
    allDataJson {
    edges {
        node {
            About_Us
            Better_Explanation
            Business_Name
            Contact_Details {
                Address
                Email
                Hours
                Phone
                Phone_Without_Formatting
                Service_Areas
            }
            Expert_Explanation
            Main_Services {
                Description
                Title
            }
            Other_Services {
                Description
                Title
            }
            Pages {
                Image
                Title
            }
            Qualities
            Tag_Line
            Title
            Years_Experience
        }
    }
}
}

`)
    const WebsiteContent = data.allDataJson.edges[0].node

    return (
        <BackgroundImageSelf image={StockBackground} mobileImage={StockBackground}
                             alt={"a kitchen with beautifully painted cabinets"} height={"h-128"} fixed={true}>
            <div className={"flex flex-row flex-wrap h-full items-center justify-evenly max-w-5xl mx-auto text-white"}>
                <div className={"max-w-[15rem]"}>
                    <h1 className={"text-2xl"}><span
                        className={"text-4xl text-main-color-500 font-bold"}>{WebsiteContent.Years_Experience + "+"}</span> Years
                    </h1>
                    <p className={"mt-2"}>Experience across the {WebsiteContent.Contact_Details.Service_Areas} area</p>
                </div>
                <div className={"max-w-[15rem]"}>
                    <h1 className={"text-2xl"}><span
                        className={"text-4xl text-main-color-500 font-bold"}>Local</span> Business</h1>
                    <p className={"mt-2"}>We are a local business proudly owned and operated right here in town.</p>
                </div>
                <div className={"max-w-[15rem]"}>
                    <h1 className={"text-2xl"}><span
                        className={"text-4xl text-main-color-500 font-bold"}>100%</span> Satisfaction</h1>
                    <p className={"mt-2"}>We don't settle for mediocre - we're satisfied only when you're 100%
                        satisfied.</p>
                </div>
            </div>

        </BackgroundImageSelf>
    )
}