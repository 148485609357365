import * as React from 'react'
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

function SingleService(props){
    return(
        <>
        <div className={"lg:w-1/2 w-full"}>
            <StaticImage  src={"../images/general/forest.webp"} alt={"services"}/>
        </div>
            <div className={"lg:w-1/2 w-full flex flex-col justify-center items-center p-5"}>
                <h2 className={"text-center font-bold text-3xl pb-5"}>heading of the service</h2>
                <p className={"text-center leading-loose"}>Simple 10-15 word description</p>
                <Link className={"pt-10"} to={"/services#placeholder"}>
                    <button className={"bg-main-color-500 hover:bg-main-color-300 text-lg text-white font-bold py-4 px-8 rounded-full"}>View More</button>
                </Link>
            </div>
        </>
    )
}

function SingleServiceReverse(props){
    return(
        <>
            <div className={"lg:w-1/2 w-full flex flex-col justify-center items-center p-5"}>
                <h2 className={"text-center font-bold text-3xl pb-5"}>heading of the service</h2>
                <p className={"text-center leading-loose"}>Simple 10-15 word description</p>
                <Link className={"pt-10"} to={"/services"}>
                    <button className={"bg-main-color-500 hover:bg-main-color-300 text-lg text-white font-bold py-4 px-8 rounded-full"}>View More</button>
                </Link>
            </div>
            <div className={"lg:w-1/2 w-full"}>
                <StaticImage  src={"../images/general/forest.webp"} alt={"services"}/>
            </div>
        </>
    )
}

export default function ServicesLarge(){
    return(
        <div className={"flex flex-row flex-wrap dark:bg-gray-700 dark:text-white"}>
            <div className={"lg:w-1/2 w-full"}>
                <StaticImage  src={"../images/home-services/turf.jpg"} alt={"services"}/>
            </div>
            <div className={"lg:w-1/2 w-full flex flex-col justify-center items-center p-5 max-w-lg mx-auto"}>
                <h2 className={"text-center font-bold text-3xl pb-5"}>Artificial Grass</h2>
                <p className={"text-center leading-loose"}>From your own backyard golf hole to dog safe play areas, our artificial grass will bring a vibrant green to your yard, all without the maintenance and water bills! Our turf is completely dog safe, lead free, and made right here in the USA!</p>
                <Link className={"pt-10"} to={"/services#artificial-grass"}>
                    <button className={"bg-main-color-500 hover:bg-main-color-300 text-lg text-white font-bold py-4 px-8 rounded-full"}>View More</button>
                </Link>
            </div>




            <div className={"lg:w-1/2 w-full flex flex-col justify-center items-center p-5  max-w-lg mx-auto"}>
                <h2 className={"text-center font-bold text-3xl pb-5"}>Pavers and Concrete</h2>
                <p className={"text-center leading-loose"}>Add a beautiful path or driveway to your home with interlocking pavers, they will never crack and the pavers come with a 25 year lifetime warranty! We will make your vision come wo life with a huge variety of styles and color options!</p>
                <Link className={"pt-10"} to={"/services#pavers-and-concrete"}>
                    <button className={"bg-main-color-500 hover:bg-main-color-300 text-lg text-white font-bold py-4 px-8 rounded-full"}>View More</button>
                </Link>
            </div>
            <div className={"lg:w-1/2 w-full"}>
                <StaticImage className={"w-full"}  src={"../images/home-services/pavers.jpg"} alt={"services"}/>
            </div>



            <div className={"lg:w-1/2 w-full"}>
                <StaticImage  className={"w-full"} src={"../images/home-services/remodel.jpg"} alt={"services"}/>
            </div>
            <div className={"lg:w-1/2 w-full flex flex-col justify-center items-center p-5  max-w-lg mx-auto"}>
                <h2 className={"text-center font-bold text-3xl pb-5"}>Back Yard Remodeling</h2>
                <p className={"text-center leading-loose"}>Bring your dream back yard to life, our experts will work with you to create an amazing modern yard with the all amenities you could ever imagine (fire pits, water features, landscaping, patios & more)</p>
                <Link className={"pt-10"} to={"/services#back-yard-remodelling"}>
                    <button className={"bg-main-color-500 hover:bg-main-color-300 text-lg text-white font-bold py-4 px-8 rounded-full"}>View More</button>
                </Link>
            </div>



            <div className={"lg:w-1/2 w-full flex flex-col justify-center items-center p-5 max-w-lg mx-auto"}>
                <h2 className={"text-center font-bold text-3xl pb-5"}>Swimming Pools and Spa</h2>
                <p className={"text-center leading-loose"}>Cool down with your family and friends with your own pool! We do water features, hot tubs, pool re-plasters, custom pools, and more! No pool is too big or small, we can do it all.</p>
                <Link className={"pt-10"} to={"/services#swimming-pools-and-spa"}>
                    <button className={"bg-main-color-500 hover:bg-main-color-300 text-lg text-white font-bold py-4 px-8 rounded-full"}>View More</button>
                </Link>
            </div>
            <div className={"lg:w-1/2 w-full"}>
                <StaticImage  src={"../images/home-services/pool.jpg"} alt={"services"}/>
            </div>
        </div>
    )
}