import * as React from 'react'
import BackgroundImageSelf from "../components/background-image-self";
import GlanceInfo from "../components/glance-info";
import Nav from "../components/nav"
import Footer from "../components/footer"
import CtaBar from "../components/cta-bar";
import Button from "../components/button";
import ReviewSection from "../components/review-section";
import Services from "../components/services";
import AboutBusiness from "../components/about-business";
import BeforeAndAfter from "../components/before-and-after";
import SimplePhotoAndText from "../components/simple-photo-and-text";
import MoreBusinessInfo from "../components/more-business-info";
import CtaBarAlternate from "../components/Cta-bar-alternate";
import StockBackground from "../images/general/forest.webp"
import { graphql } from "gatsby";

import {Helmet} from "react-helmet";
import ExperienceBar from "../components/experience-bar";
import Hero from "../components/hero";
import ImageGallery from "../components/image-gallery";
import ImageGalleryShowcase from "../components/image-gallery-showcase";
import AdditionalServices from "../components/additional-services";
import ServicesLarge from "../components/services-large";

//npx gatsby develop

export const query = graphql`
  query {
    allDataJson {
    edges {
        node {
            About_Us
            Better_Explanation
            Business_Name
            Contact_Details {
                Address
                Email
                Hours
                Phone
                Phone_Without_Formatting
                Service_Areas
            }
            Expert_Explanation
            Main_Services {
                Description
                Title
            }
            Other_Services {
                Description
                Title
            }
            Pages {
                Image
                Title
            }
            Qualities
            Tag_Line
            Title
            Years_Experience
        }
    }
}
}

`

const IndexPage = ({ data }) => {
    const WebsiteContent = data.allDataJson.edges[0].node

    return (
        <>
            <Helmet>
                <title>{WebsiteContent.Business_Name + " | " + WebsiteContent.Title}</title>
                <meta name="description" content={WebsiteContent.Tag_Line} />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <main className={"font-roboto"}>

                <Nav/>
                <Hero/>

                <AboutBusiness />
                <CtaBar/>
                <ServicesLarge/>
                <ExperienceBar/>
                <ImageGalleryShowcase/>
                <ReviewSection/>
                <CtaBarAlternate/>
























            </main>
            <Footer />
        </>
    )
}

export default IndexPage